import axios from 'axios'

export default async function fileUploader(url, method, data) {
  const formData = new FormData(data)

  return await axios({
    method: method,
    url: url,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
