import $ from 'jquery'
import 'select2'
import 'bootstrap'
import fileUploader from '@/utils/fileUploader'

$(document).on('click', '[data-behavior=trigger-camera-guide]', () => {
  console.log('trigger camera guide')
  $('#camera-guide-modal').modal('show')
})

$(document).on('click', '[data-behavior=trigger-file-field]', (e) => {
  e.preventDefault()
  // Simulate click on file field
  let file_field = $('[data-image-upload-form]').find(':file')
  file_field.trigger('click')
})

$(document).on('change', '[data-behavior=check-transaction]', (e) => {
  e.target.parentElement.click()
})

$(document).on('change', '[data-image-upload-form]', async (e) => {
  e.preventDefault()
  let form = $(e.currentTarget)
  form.find('[data-behavior=spin-when-uploading]').removeClass('hidden')
  form.find('[data-behavior=hide-when-uploading]').addClass('hidden')
  let submitUrl = form.data('submit-url')
  let submitMethod = form.data('submit-method')
  let data = form[0]
  fileUploader(submitUrl, submitMethod, data).then(() => {
    window.location.reload()
  })
})

$(document).on('turbolinks:load', () => {
  $('[data-behavior~=searchable-select]').select2()
  if (document.URL.indexOf('/cookies') <= -1 && navigator.cookieEnabled != true)
    window.location.href = 'cookies'
})

$(document).on('click', '[data-behavior~=disable-on-click]', () =>
  $('[data-behavior~=disable-on-click]').addClass('disabled'),
)

// Hack necesary to comply to the "signin=success" concept for Turbolinks app logins

$(document).on('click', '[data-behavior~=hijacked-sign-in]', (e) => {
  e.preventDefault()
  $.get($(e.currentTarget).attr('href'))
})

//
// Bootstrap panels
//
// Restrict collapsable panels to only have one open at a time
$(document).on('show.bs.collapse', '[data-single-panel-open]', () =>
  $('.panel-group .in').collapse('hide'),
)

$(document).on('click', '.dropdown-toggle', (e) => {
  e.preventDefault()
  $(e.target).parent('.dropdown').toggleClass('open')
})

let ping_timer = null
document.addEventListener('turbolinks:load', () => {
  if ($('[data-ping-url]').data('ping-url'))
    ping_timer = setInterval(() => {
      $.ajax({
        type: 'GET',
        url: $('[data-ping-url]').data('ping-url'),
      })
    }, 20000)
  else clearInterval(ping_timer)
})

//
// Upload preview
//
if ($('.image-preview').length > 0) {
  $.uploadPreview = {
    label_selected: '📷 Vælg et andet billede',
    input_field: '.image-upload',
    label_field: '.image-label',
    preview_box: '.image-preview-box',
  }
}
